.form-group > label {
  bottom: 0px;
  left: 0px;
  position: relative;
  padding: 0px 0px 0px 0px;
  font-size: 0.9em;
  transition: 0.2s;
  pointer-events: none;
}

.form-group > input {
  color: black;
}

/* 
.form-control:focus ~ label {
  bottom: 0px;
  font-size: 1em;
  color: rgb(117, 117, 117);
}

.form-control:valid ~ label {
  bottom: 0px;
  font-size: 1em;
  color: rgb(117, 117, 117);
}

.dark .form-group > label {
  bottom: 34px;
  left: 15px;
  position: relative;
  background-color: rgb(165, 183, 199);
  padding: 0px 5px 0px 5px;
  font-size: 1.1em;
  transition: 0.2s;
  pointer-events: none;
}

.dark .form-control:focus ~ label {
  bottom: 56px;
  background-color: rgb(6, 6, 6);
  border-radius: 5px;
  font-size: 1em;
}

.dark .form-control:valid ~ label {
  bottom: 56px;
  background-color: rgb(6, 6, 6);
  border-radius: 5px;
  font-size: 1em;
} */
