.ProductPage .AccordionBottomShadow{
  z-index:4;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; 
  -moz-box-shadow:    inset  0 -20px 20px -20px rgb(114,179,104);
  -webkit-box-shadow: inset  0 -20px 20px -20px rgb(114,179,104);
   box-shadow:        inset  0 -20px 20px -20px rgb(114,179,104);
}

.ProductPage .image-div {
  width: 130%;
}

@media only screen and (min-width: 768px) {
  .ProductPage .image-div {
    width: 100%;
    left: 0%;
  }
}

.ProductPage .my-image {
  position: relative;
  left: -15%;
}

@media only screen and (min-width: 768px) {
  .ProductPage .my-image {
    position: relative;
    left: 0%;
  }
}

.ProductPage .product-name {
  font-size: 160%;
}

@media only screen and (min-width: 768px) {
  .ProductPage .product-name {
    font-size: 220%;
  }
}

.ProductPage .content-div {
  position: absolute;
  top: 0;
  left: 0;
}

.ProductPage .main-text {
  color: #fff;
  position: absolute;
  top: 50px;
  width: 96.6667%;
}

.ProductPage .carousel {
  border-radius: 5px 5px 0px 0px;
  overflow: hidden;
}

.ProductPage .jumbotron {
  border-radius: 0px 0px 5px 5px;
  overflow: hidden;
}
/* 
.ProductPage .horizontal-slider > .row {
  overflow-x: auto;

}

.ProductPage .horizontal-slider > .row > .col-5 {
  display: inline-block;
  float: none;
} */

.ProductPage .tarjeta-video {
  min-width: 280px;
}
