.grecaptcha-badge {
  visibility: hidden;
}

.App {
  margin-top: 50px;
}

@media (min-width: 768px) {
  .App {
    margin-top: 50px;
  }
}

.App .navbar-brand {
  font-weight: bold;
  font-size: 18px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.App .navbar {
  background-color: rgb(255, 136, 0) !important;
}

@media (min-width: 768px) {
  .App .navbar {
    height: 50px;
  }
}

.dark .navbar {
  background-color: rgb(219, 94, 25) !important;
}

.ShoppingBag .nav-pills .nav-link.active,
.nav-pills.show > .nav-link {
  color: rgb(255, 255, 255);
  background-color: rgb(46, 46, 46);
}

.ShoppingBag a {
  color: rgb(104, 104, 104);
  text-decoration: none;
  background-color: transparent;
}

body {
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif !important;
  font-size: 16px !important;
  color: rgb(79, 79, 79);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lead {
  font-weight: 350;
  font-size: 1.018 rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato";
  font-weight: 530 !important;
}

.dark {
  color: rgb(168, 168, 168);
}

.light .buttonLogin {
  margin-top: 5px !important;
  height: 41px;
}

.dark .buttonLogin {
  margin-top: 0px !important;
  height: 41px;
}

h1 {
  font-size: 38px;
}

.dark h1 {
  font-size: 38px;
  color: rgb(168, 168, 168);
}
.dark h2 {
  font-size: 30px;
  color: rgb(168, 168, 168);
}

.dark h3 {
  font-size: 1.421875rem;
  color: rgb(168, 168, 168);
}

.dark h4 {
  font-size: 1.21875rem;
  color: rgb(168, 168, 168);
}

.dark table {
  color: rgb(168, 168, 168);
}

.dark h5 {
  font-size: 1.1rem;
  color: rgb(168, 168, 168);
}

/* .dark a {
  color: rgb(190, 190, 190) !important;
} */

.dark .lead {
  font-size: 16.5px;
}

.dark select.form-control,
.dark textarea.form-control,
.dark input.form-control {
  font-size: 16px;
  background-color: rgb(72, 95, 116);
  border-color: rgb(72, 95, 116);
  color: rgb(218, 218, 218);
}

.dark input.form-control:enabled,
.dark textarea.form-control:enabled {
  background-color: rgb(72, 95, 116);
  border-color: rgb(72, 95, 116);
  color: rgb(218, 218, 218);
}

.dark .form-control::-webkit-input-placeholder {
  color: rgba(145, 145, 145, 0.74);
}

input[type="file"] {
  width: 100%;
}

.form-control:focus {
  outline: none;
  border-color: none;
  box-shadow: none;
  outline-width: 0;
  color: #333;
}

.form-control {
  color: #333;
}

.facebook {
  color: #3b5998;
}

hr.dashed {
  border-top: 2px dashed #999;
}

hr.dotted {
  border-top: 2px dotted #999;
}

hr.solid {
  border-top: 2px solid #999;
}

hr.hr-text {
  position: relative;
  border: none;
  height: 1px;
  background: #999;
}

hr.hr-text::before {
  content: attr(data-content);
  display: inline-block;
  background: #fff;
  font-weight: bold;
  font-size: 0.85rem;
  color: #999;
  border-radius: 8rem;
  padding: 0.1rem 0.8rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fill {
  min-height: 100%;
  height: 100%;
}

.App .ShoppingBar {
  height: 100vh;
  width: 75vw;
  background-image: linear-gradient(to bottom, #2a5298, #1e3c72);
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 10;
}

@media (min-width: 768px) {
  .App .ShoppingBar {
    width: 35vw;
  }
}

@media (min-width: 992px) {
  .App .ShoppingBar {
    width: 25vw;
  }
}

/* body::-webkit-scrollbar {
  display: none;
} */

/* Hide scrollbar for IE, Edge and Firefox */
/* body {
  -ms-overflow-style: none;
  scrollbar-width: none; 
} */

/* body::-webkit-scrollbar-thumb:hover {
  background: rgb(120, 120, 120);
  width: 8px;
  display: inline;
} */

/* width */
.Routes ::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.Routes ::-webkit-scrollbar-corner {
  display: none;
}

/* Track */
/* .Routes ::-webkit-scrollbar-track {
  background: transparent;
  margin-top: 10px;
  margin-bottom: 10px;
} */

/* Handle */
.Routes ::-webkit-scrollbar-thumb {
  background: rgb(120, 120, 120);
  border-radius: 3px;
}

/* Handle on hover */
.Routes ::-webkit-scrollbar-thumb:hover {
  background: rgb(120, 120, 120);
}
