.Home .Title {
    font-size: 28px;
}

.Home .Description {
    font-size: 125%;
}

.Home .ContainerView{
    min-height: 80vh;
}

@media (min-width: 768px) { 
    .Home .Title {
        font-size: 40px;
    }
    .Home .Description {
        font-size: 135%;
    }
    .Home .ContainerView{
        min-height: 70vh;
    }
  }
