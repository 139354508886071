.LoginForm .Google svg  {
    fill: rgb(116, 116, 116);
}

.LoginForm .Google  {
    background-color: white !important;
    color: rgb(80, 80, 80) !important;
}

.dark .LoginForm .Facebook{
    background-color: #4267B2!important;
}

.dark .LoginForm .Google  {
    background-color: rgb(219, 219, 219) !important;
}

.dark .LoginForm .Google svg  {
    fill: rgb(66, 66, 66);
}
