.Signup .Google svg {
  fill: rgb(116, 116, 116);
}

.Signup .Google {
  background-color: white !important;
  color: rgb(80, 80, 80) !important;
}

.dark .Signup .Facebook {
  background-color: #4267b2 !important;
}

.dark .Signup .Google {
  background-color: rgb(219, 219, 219) !important;
}

.dark .Signup .Google svg {
  fill: rgb(66, 66, 66);
}
