.FacebookReviewsCarousel .Testimonial {
  min-height: 45vh;
  max-height: 45vh;
}

.FacebookReviewsCarousel .Testimonial .QuoteText {
  min-height: 33vh;
  max-height: 33vh;
}

.FacebookReviewsCarousel .ContainerView {
  min-height: 80vh;
}

@media (min-width: 768px) {
  .FacebookReviewsCarousel .Title {
    font-size: 40px;
  }
  .FacebookReviewsCarousel .Description {
    font-size: 135%;
  }
  .FacebookReviewsCarousel .ContainerView {
    min-height: 80vh;
  }
}
