.ShoppingSideBar .Products {
  min-height: 51vh;
  max-height: 51vh;
  overflow-y: auto;
  overflow-x: auto;
}

@media (min-width: 768px) {
  .ShoppingSideBar .Products {
    min-height: 60vh;
    max-height: 60vh;
  }
}
